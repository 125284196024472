<template>
  <Modal
    closePosition="visible"
    :containerClass="`clipperModalPosition overflow-hidden ${exportStage > 1 ? 'cmp2': 'cmp1'}`"
    :overlayClass="`${exportStage > 1 ? 'clipperExportModalOverlay' : ''} ${exportStage===3?'opacity-95':''}`"
    :onClose="handleModalClose"
    >
    <div class="transition-all duration-300 ease-in p-6 min-w-s720 clipperModalWidth"
      :class="{
        'h-48': exportStage < 3,
        'h-s512': exportStage === 3
      }"
    >
      <transition>
      <div
        v-show="exportStage === 1"
        class="flex flex-col h-full"
      >
        <h1
          class="text-2xl text-primary font-h"
        >
          Editing the doc now can change your clips.
        </h1>
        <h2 class="text-2xl text-primary font-h font-semibold">
          Export them before continuing?
        </h2>
        <div class="mt-auto flex justify-end items-center gap-2 uppercase">
          <Button
            variant="secondary"
            :onClick="handleDeleteAndExit"
          >No, delete clips & continue editing</Button>
          <Button
            variant="primary"
            :onClick="() => delayedStageChange(2)"
          >Yes, Export clips first & continue editing</Button>
        </div>
      </div>
      </transition>

      <transition>
      <div
        v-show="exportStage === 2"
        class="flex flex-col h-full"
      >
        <h1
          class="inline text-2xl text-primary font-h"
        >
          Export clips with your
        </h1>
        <h2 class="inline text-2xl text-primary font-h font-semibold">
          branding and subtitles?
        </h2>
        <div v-if="!exportLoading" class="mt-auto flex justify-end items-center gap-2 uppercase">
          <Button
            variant="secondary"
            :onClick="() => triggerExport(false)"
          >No, EXPORT CLIPS WITHOUT BRANDING</Button>
          <Button
            variant="primary"
            :onClick="() => delayedStageChange(3)"
          >Yes, APPLY BRANDING</Button>
        </div>
        <div v-else-if="exportLoading" class="mt-auto flex justify-end items-center gap-2 uppercase">
          <Button
            :variant="'primary'"
          >Exporting without branding...</Button>
        </div>
      </div>
      </transition>
      <transition>
        <div
          v-show="exportStage === 3"
          class="flex flex-col h-full"
        >
          <h1
            class="inline text-2xl text-primary font-h font-semibold"
          >
            Apply Brand Kit
          </h1>
          <span class="text-sm text-primary font-ui mb-8">
            You can preview your clips with the brand kit on the right 👉
          </span>
          <BrandKitWrapper
            ref="brandkitRef"
            :subsExpanded="true"
            :initialEmit="true"
            @onChange="setBrandkit"
          />
          <div v-if="!exportLoading" class="mt-auto flex justify-end items-center gap-2 uppercase">
            <Button
              variant="secondary"
              :onClick="() => triggerExport(false)"
            >
              <span>No, EXPORT CLIPS WITHOUT BRANDING </span>

            </Button>
            <Button
              variant="primary"
              :onClick="() => triggerExport(true)"
            >Done, Export Clips</Button>
          </div>
          <div v-else-if="exportLoading" class="mt-auto flex justify-end items-center gap-2 uppercase">
            <Button
              :variant="'primary'"
            >Exporting...</Button>
          </div>
        </div>
      </transition>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/base/dialogs/Modal.vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import Button from '@/components/base/buttons/Button.vue'
import BrandKitWrapper from '@/view/Workflows/components/BrandKit.vue'
import store from '@/services/store'

export default {
  name: 'ClipperExportModal',
  components: { Modal, Button, BrandKitWrapper },
  computed: {
    ...mapGetters({
      hasEdited: 'clipper/hasEdited',
      exportStage: 'clipper/exportStage',
      clipper: 'clipper/clipper',
      exportLoading: 'clipper/exportLoading',
      useBrandkit: 'clipper/useBrandkit',
      isPlaying: 'editor/isPlaying',
      clips: 'clipper/clips'
    })
  },
  methods: {
    ...mapActions({
      closeModal: 'dialogs/closeModal'
    }),
    ...mapMutations({
      setHasEdited: 'clipper/setHasEdited',
      setExportStage: 'clipper/setExportStage'
    }),
    handleDeleteAndExit() {
      // add delete logic
      this.clipper.deleteAllClips()
      this.closeModal()
    },
    handleModalClose() {
      if (this.exportStage === 3) {
        this.clipper.resetDocState()
      }
      this.setHasEdited(false)
      this.setExportStage(1)
    },
    /**
     * Avoids a weird glitch where for 300ms vue transition
     * sets both the divs as visible causing a weird overflow issue
    */
    delayedStageChange(to) {
      this.setExportStage(1.5)
      setTimeout(() => {
        this.setExportStage(to)
      }, 300)
    },
    async setBrandkit(next = true) {
      await this.clipper.setBrandkit()
      if (next) store.dispatch('clipper/makePreviewClips')
    },
    triggerExport(useBrandkit) {
      if (this.isPlaying) store.dispatch('editor/toggleState', false)
      store.dispatch('clipper/beginExport', useBrandkit)
    }
  },
  watch: {
    exportStage(val) {
      if (val === 3) {
        this.clipper.setUseBrandkit(true).then(() => {
          this.clipper.setBrandkit().then((bkit) => {
            if (bkit && bkit.logo) this.$refs.brandkitRef.scheduleLogoChange(bkit.logo)
            if (bkit && bkit.logoStyle) this.$refs.brandkitRef.scheduleLogoStyleChange(bkit.logoStyle)
            store.dispatch('clipper/makePreviewClips')
          })
        })
      } else {
        this.clipper.setUseBrandkit(false)
        this.$refs.brandkitRef.scheduleLogoChange('')
      }
    }
  },
  beforeDestroy() {
    this.$refs.brandkitRef.scheduleLogoChange('')
    store.commit('clipper/setSelectedClip', null)
  }
}
</script>

<style lang="scss">
  .clipperModalPosition {
    vertical-align: unset;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
  }

  .cmp2 {
    left: 11%;
    transform: translate(0%, -50%);
  }

  .cmp1 {
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .clipperModalWidth {
    width: 45vw;
  }

  .v-leave-active,
  .v-enter-active {
    transition: opacity 0.3s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }

  .clipperExportModalOverlay {
    width: 62%;
  }
</style>
